<template>
  <CModal
      :show.sync="modalRevertirAnular"
      :no-close-on-backdrop="true"
      :centered="true"
      :closeOnBackdrop="false"
      title="Anular Factura"
      size="lg"
      color="dark"
  >
    <template #header>
      Revertir Anulación de Factura
    </template>
    <div class="row pt-3">
      <div class="col">
        <div class="row">
          <template v-if="confirmarAnular">
            <div class="col-12 text-center pt-3">
              <button class="btn btn-success mx-2" @click="revertirAnularFactura">Revertir Anulación de Factura</button>
              <button class="btn btn-secondary mx-2" type="button" @click="close">Cerrar esta
                ventana
              </button>
            </div>
          </template>
          <template v-else>
          <div class="col text-center" v-if="esperandoRespuesta">
            <h4>Esperando a que el servicio de impuestos procese la factura</h4>
            <h5>Tiempo transcurrido
              <span
                  :class="'text-'+(tiempo<15?'success':(tiempo<30?'warning':'danger'))">
                                                        {{ tiempo }}</span> segundos.
              <button v-if="continuarEsperando" class="btn btn-sm btn-warning ms-2"
                      @click="tiempoAxios=0;continuarEsperando=false;temporizador();temporizadorAxios();">
                Seguir esperando la respuesta
              </button>
            </h5>
          </div>
          <div class="col text-center" v-if="!esperandoRespuesta">
            <h4>Factura Nro: <span>{{ factura.numeroFactura }}</span></h4>
            <h4>Estado: <span>{{ factura.estadoFactura }}</span></h4>
            <h4>Mensaje: <span>{{ factura.codigoError }} {{ factura.mensajeRespuesta }}</span></h4>
          </div>
          </template>
        </div>
      </div>
    </div>
    <template #footer-wrapper>
      <template v-if="!confirmarAnular">
      <h6 class="px-3"><em>En el listado de facturas tambien puede consultar el estado de la factura</em>
        <button class="btn btn-secondary" type="button" @click="close">Cerrar esta
          ventana
        </button>
      </h6>
      </template>
      <template v-else>&nbsp;</template>
    </template>
  </CModal>
</template>

<script>
import axios from "axios";

var modalRevertirAnular;
export default {
  name: "Modal",
  data() {
    return {
      confirmarAnular:true,
      tiempo: 0,
      tiempoAxios: 0,
      factura: null,
      intervalo: null,
      intervaloAxios: null,
      sede_id: 0,
      factura_id: 0,
      codigo_motivo: '',
      continuarEsperando: false,
      esperandoRespuesta: true,
      modalRevertirAnular: false,
      motivos:[]
    }
  },
  computed:{
    urlfactura() {
      let time = new Date().getTime()
      return this.$apiAdress + '/api/facturaenlinea/' + this.factura.id + '/facturapdf?token=' + localStorage.getItem('api_token')+'&timestamp='+time
    }
  },
  methods: {
    mostrar(sede_id, factura_id) {
      this.confirmarAnular=true
      this.sede_id = sede_id
      this.factura_id = factura_id
      modalRevertirAnular.modalRevertirAnular = true;
    },
    revertirAnularFactura() {
      this.confirmarAnular=false
      this.esperandoRespuesta = true
      this.tiempo = 0
      this.tiempoAxios = 0
      this.revertirAnular()
      this.loadItems(this.sede_id, this.factura_id)
      modalRevertirAnular.temporizador()
      modalRevertirAnular.temporizadorAxios()
    },
    close() {
      clearInterval(modalRevertirAnular.intervaloAxios)
      clearInterval(modalRevertirAnular.intervalo)
      modalRevertirAnular.modalRevertirAnular = false;
    },
    temporizador() {
      modalRevertirAnular.intervalo = setInterval(() => {
        modalRevertirAnular.tiempo++;
      }, 1000)
    },
    temporizadorAxios() {
      modalRevertirAnular.intervaloAxios = setInterval(() => {
        if (modalRevertirAnular.tiempoAxios > 21) {
          clearInterval(modalRevertirAnular.intervaloAxios)
          clearInterval(modalRevertirAnular.intervalo)
          modalRevertirAnular.continuarEsperando = true
          return;
        }
        modalRevertirAnular.tiempoAxios++;
        modalRevertirAnular.loadItems(this.sede_id, this.factura_id)
      }, 15000)
    },
    async loadItems(sede_id, factura_id) {
      modalRevertirAnular.processing = true
      let time = new Date().getTime()
      await axios.post(this.$apiAdress + '/api/facturaenlinea/' + sede_id + '/verify?token=' + localStorage.getItem("api_token")+
          '&timestamp='+time,
          {factura_id: factura_id}
      )
          .then(function (response) {
            console.log(response.data)
            modalRevertirAnular.factura = response.data.factura;
            console.log(response.data.factura.estadoFactura);
            if (response.data.factura.estadoFactura == 'ANULADA') {
              modalRevertirAnular.$emit('actualizaGrilla')
              clearInterval(modalRevertirAnular.intervaloAxios)
              clearInterval(modalRevertirAnular.intervalo)
              modalRevertirAnular.esperandoRespuesta = false
              axios.get(
                  modalRevertirAnular.urlfactura,
                  {responseType: 'blob'}
              )
                  .then((response) => {
                    modalRevertirAnular.modalRevertirAnular = false;
                    modalRevertirAnular.$emit('mostrarDocumento', response.data)
                  })
                  .catch(function (error) {
                    modalRevertirAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
                  });
            } else if (response.data.factura.estadoFactura === 'RECHAZADA') {
              clearInterval(modalRevertirAnular.intervaloAxios)
              clearInterval(modalRevertirAnular.intervalo)
              modalRevertirAnular.esperandoRespuesta = false
            } else if (response.data.factura.estadoFactura === 'VALIDADA') {
              if (modalRevertirAnular.tiempoAxios > 100) {
                clearInterval(modalRevertirAnular.intervaloAxios)
                clearInterval(modalRevertirAnular.intervalo)
                modalRevertirAnular.esperandoRespuesta = false
                modalRevertirAnular.$emit('showToast', 'Error', 'NO ES POSIBLE ANULAR LA FACTURA SONSULTE CON EL ADMINISTRADOR', 'danger')
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            modalRevertirAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
          }).finally(() => {
        modalRevertirAnular.processing = false
      })
    },
    async revertirAnular() {
      modalRevertirAnular.processing = true
      let time = new Date().getTime()
      await axios.post(this.$apiAdress + '/api/facturaenlinea/' + this.sede_id + '/revertiranulacion?token=' + localStorage.getItem("api_token")+
          '&timestamp='+time,
          {factura_id: this.factura_id}
      )
          .then(function (response) {

          })
          .catch(function (error) {
            modalRevertirAnular.$emit('showToast', 'Error', error.response.data.message, 'danger')
          }).finally(() => {
        modalRevertirAnular.processing = false
      })
    },
  },
  mounted() {
    modalRevertirAnular = this
  }
}
</script>
